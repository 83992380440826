<template lang="pug">
InputGroupControl(
  :label="labelOverride || $t('margin')"
  type="margin"
  :disabled="disabled"
  :customProperty="customProperty"
  :editMobile="deviceSelector"
  :allowNegative="allowNegative"
)
</template>
<script>
  import InputGroupControl from './InputGroup.vue';

  export default {
    components: { InputGroupControl },
    props: {
      labelOverride: {
        type: String,
        default: null,
      },
      customProperty: {
        type: String,
        default: null,
      },
      deviceSelector: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Array,
        default: () => [false, false, false, false],
      },
      allowNegative: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>
